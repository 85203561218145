import { networksInfo } from "./networks";

const infuraKey = process.env.VUE_APP_INFURA_KEY;

const activeNetwork = process.env.VUE_APP_NETWORK;
const network =
  networksInfo.find((network) => network.name === activeNetwork) ||
  networksInfo[0];

const apiUrl =
  network.name === "mainnet"
    ? "https://536ev5szp1.execute-api.us-east-1.amazonaws.com"
    : "https://536ev5szp1.execute-api.us-east-1.amazonaws.com";
const storageUrl =
  network.name === "mainnet"
    ? "https://storage.mint.bullsandapesproject.com"
    : "https://labs.storage.mint.bullsandapesproject.com";
const apeStorageUrl =
  network.name === "mainnet"
    ? "https://apes.mint.bullsandapesproject.com"
    : "https://labs.apes.mint.bullsandapesproject.com";

// const crossMintProjectId = ;
// const crossMintCollectionId = ;
export const Config = {
  mode: process.env.VUE_APP_ENV,
  network,
  apiUrl,
  storageUrl,
  apeStorageUrl,
  infuraKey,
  MethBagPrices: {
    1000: 0.05,
    5000: 0.21,
    11000: 0.4,
  },

  BOTBAddress: {
    OG: "0x3a8778A58993bA4B941f85684D74750043A4bB5f",
    Bears: "0x02AA731631c6D7F8241d74F906f5b51724Ab98F8",
    EVO: "0x6069a6C32cf691F5982FEbAe4fAf8a6f3AB2F0F6",
  },
  OSUrl: {
    base: "https://opensea.io/assets/ethereum",
    genesis:
      "https://opensea.io/assets/ethereum/0x2cf6be9aac1c7630d5a23af88c28275c70eb8819/",
    teens:
      "https://opensea.io/assets/ethereum/0xcf099445347f0f006c19b90f1c1933eb9413682b/",
    apes: "https://opensea.io/assets/ethereum/0x7f29b85834d6a2ba4bb1c64325686c6057b1b3c5/",
    utilities:
      "https://opensea.io/assets/ethereum/0x2923c3e5a0f10bc02d8c90287b2af45cd579dec4/",
    apeTraits:
      "https://opensea.io/assets/ethereum/0xee9e6146e635e5da5c3885b2a641321c5a50da7e/",
  },
  crossMint: {
    projectId:
      network.name === "mainnet"
        ? "af414d65-93a0-4d62-9f67-88a6e269ac4c"
        : "46c27ae2-8234-4bb8-9f71-57a571671cc0",
    traitShop: {
      collectionId:
        // TODO add prod collectionId
        network.name === "mainnet"
          ? ""
          : "d20121fe-62a3-4cdf-ac04-d035a218232d",
    },
    lootBox: {
      collectionId:
        // TODO add prod collectionId
        network.name === "mainnet"
          ? "c838d56a-142d-4a79-b426-2de3b13eaa08"
          : "c268efe6-b28f-47dc-b3c7-30d24ed7b0f9",
    },
  },
};
